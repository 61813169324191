import { Badge } from "@material-ui/core";
import * as icons from "@material-ui/icons";
import React from "react";
import { Button, Tab, useGetIdentity, useRecordContext } from "react-admin";
import { TabbedShowLayoutStyled } from "./TabbedShowLayoutStyled";
import { ActivitiesTab } from "./tabs/ActivitiesTab";
import { CommentTab } from "./tabs/CommentTab";
import { FilesTab } from "./tabs/FilesTab";
import { GeneralTab } from "./tabs/GeneralTab";
import { PaymentsTab } from "./tabs/PaymentsTab";

interface ClaimTabsProps {
  absoluteTotal: any;
  currentCreditorAmountDueInclInterest: any;
  handleCallDebtor: any;
  isPhoneReady: any;
}

export const ClaimTabs = React.memo(
  ({
    absoluteTotal,
    currentCreditorAmountDueInclInterest,
    handleCallDebtor,
    isPhoneReady,
  }: ClaimTabsProps) => {
    const {
      AppsOutlined,
      PaymentOutlined,
      ChatOutlined,
      FileCopyOutlined,
      CommentOutlined,
      CallOutlined,
    } = icons;

    const record = useRecordContext();
    const { identity } = useGetIdentity();

    return (
      <TabbedShowLayoutStyled>
        <Tab label="General" icon={<AppsOutlined />}>
          <GeneralTab />
        </Tab>
        <Tab label="Payments" icon={<PaymentOutlined />}>
          <PaymentsTab
            absoluteTotal={absoluteTotal}
            currentCreditorAmountDueInclInterest={
              currentCreditorAmountDueInclInterest
            }
          />
        </Tab>
        <Tab label="Activities" icon={<ChatOutlined />}>
          <ActivitiesTab />
        </Tab>
        {(identity?.username?.includes("debtist.de") ||
          identity?.username === "admin") && (
          <Tab label="Files" icon={<FileCopyOutlined />}>
            <FilesTab />
          </Tab>
        )}
        <Tab
          label="Comment"
          icon={
            <Badge
              invisible={!record?.comment?.length}
              badgeContent={<></>}
              color={"primary"}
              variant="dot"
              style={{ marginTop: 4 }}
            >
              <CommentOutlined />
            </Badge>
          }
          style={{
            background: record?.comment?.length ? "var(--primary)" : "",
            color: record?.comment?.length ? "#fff" : "",
          }}
        >
          <CommentTab />
        </Tab>

        <Button
          hidden={!record.debtor?.phone}
          title="Call Debtor"
          disabled={!isPhoneReady}
          onClick={handleCallDebtor}
          style={{
            position: "absolute",
            right: 25,
            top: "50%",
            padding: 8,
            transform: "translateY(-50%)",
          }}
          size="small"
        >
          <CallOutlined />
        </Button>
      </TabbedShowLayoutStyled>
    );
  }
);

import { CloseOutlined } from "@material-ui/icons";
import React, { useCallback, useEffect } from "react";
import Draggable from "react-draggable";
import { usePhone } from "../context/PhoneContext";

interface CloseButtonProps {
  onClick: () => void;
}

const CloseButton = React.memo<CloseButtonProps>(({ onClick }) => (
  <div
    style={{
      display: "block",
      position: "fixed",
      right: -15,
      top: -15,
      height: 35,
      width: 35,
      borderRadius: 30,
      background: "#32c587",
      color: "#fff",
      cursor: "pointer",
      zIndex: 9999,
    }}
    onClick={onClick}
  >
    <CloseOutlined style={{ padding: 5, paddingTop: 7 }} />
  </div>
));

export const PhoneFrame = React.memo(() => {
  const { initializeAircall, showAirCallPhone, setShowAirCallPhone } =
    usePhone();

  useEffect(() => {
    if (initializeAircall) {
      setTimeout(() => initializeAircall(), 0);
    }
  }, [initializeAircall]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (showAirCallPhone) {
        if (event.preventDefault) {
          event.preventDefault();
        } else {
          // For older browsers
          event.returnValue = "";
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [showAirCallPhone]);

  const handleClose = useCallback(() => {
    setShowAirCallPhone(false);
  }, [setShowAirCallPhone]);

  return (
    <Draggable handle=".handle">
      <div className={`aircallPhone ${showAirCallPhone ? "-open" : ""}`}>
        <div className="handle">Drag phone here</div>
        <div id="phone" />
        {showAirCallPhone && <CloseButton onClick={handleClose} />}
      </div>
    </Draggable>
  );
});

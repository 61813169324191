import { Typography } from "@material-ui/core";
import React from "react";
import {
  Button,
  Datagrid,
  ExportButton,
  Pagination,
  ReferenceManyField,
  useDataProvider,
  useListContext,
  useRecordContext,
  useRefresh,
} from "react-admin";
// todo: clean up these imports
import { ActivityCreate } from "../../../../activity/ActivityCreate";
import {
  Activity,
  getReadableActivityType,
} from "../../../../api/activity/Activity";
import { Claim } from "../../../../api/claim/Claim";
import ActivityEventItemView, {
  ActivityComment,
} from "../../../ActivityEventItem";
import { ClaimActivityTools } from "../../../ClaimActivityTools";

const ActivityInlineCreate = React.memo(
  (props: {
    activityPlaceholderText?: string;
    setActivityPlaceholderText?: any;
  }) => {
    const record: Claim = useRecordContext();

    return (
      <div>
        <Typography id="new-activity" variant="caption">
          New Activity
        </Typography>
        <ActivityCreate
          basePath="/Activity"
          resource="Activity"
          options={{
            inline: true,
            claimId: record?.id,
            placeholderComment: props.activityPlaceholderText,
            setPlaceholderComment: props.setActivityPlaceholderText,
          }}
        />
      </div>
    );
  }
);

const ActivityListItems = () => {
  const listContext = useListContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const ActivityExportButton = () => {
    const { data } = useListContext();

    const activities: Activity[] = Object.values(data) as Activity[];

    const downloadCSV: (filename: string, data: Activity[]) => void = (
      filename: string,
      data: Activity[]
    ) => {
      // Logic to convert activities data to CSV format and initiate download
      const csvData: string = activities
        .map(
          (activity) =>
            `${activity.activityDate || ""},${activity.id || ""},${
              getReadableActivityType(activity.activityType) || ""
            },${activity.claimAction || ""},${activity.comment || ""}`
        )
        .join("\n");
      const csvHeader = "Date, ID, Activity Type, Action, Comment";
      const csvWithHeader: string = `${csvHeader}\n${csvData}`;

      const blob = new Blob([csvWithHeader], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    };

    return (
      <ExportButton
        style={{ marginTop: 10, float: "right" }}
        onClick={() => downloadCSV(`activities.csv`, activities)}
      />
    );
  };

  return (
    <>
      <Datagrid
        optimized
        hasBulkActions
        selectedIds={listContext.selectedIds}
        rowStyle={(record) => {
          if (
            record?.activityType?.startsWith("Recommended") &&
            !record?.isPlanned
          ) {
            return {
              display: "none",
            };
          }

          if (record?.isPlanned) {
            return {
              backgroundColor: "#ebfaff",
            };
          }
        }}
        isRowSelectable={(record) => record?.isPlanned}
        rowClick="edit"
        style={{
          overflow: "hidden",
          overflowX: "auto",
        }}
      >
        <ActivityEventItemView />
        <ActivityComment />
      </Datagrid>

      {!!listContext.selectedIds && !!listContext.selectedIds.length && (
        <Button
          variant="outlined"
          style={{ marginTop: 20 }}
          onClick={async () => {
            for (const selectedId of listContext.selectedIds) {
              await dataProvider.delete("Activity", {
                id: selectedId,
              });
            }
            listContext.onUnselectItems();
            refresh(true);
          }}
          label="Delete"
        />
      )}
      <ActivityExportButton />
    </>
  );
};

export const ActivitiesTab: React.FC = () => {
  const [showActivityAdd, setShowActivityAdd] = React.useState(false);
  const [activityPlaceholderText, setActivityPlaceholderText] =
    React.useState("");

  return (
    <>
      <ClaimActivityTools
        showActivityAdd={showActivityAdd}
        onCreateButtonClick={(placeholderText?: string) => {
          setActivityPlaceholderText(placeholderText);
          setShowActivityAdd(!showActivityAdd);
        }}
      />
      {showActivityAdd && (
        <ActivityInlineCreate
          activityPlaceholderText={activityPlaceholderText}
          setActivityPlaceholderText={setActivityPlaceholderText}
        />
      )}
      <ReferenceManyField
        reference="Activity"
        target="ClaimId"
        label="Activities"
        fullWidth
        perPage={100}
        pagination={<Pagination />}
        sort={{ field: "activityDate", order: "DESC" }}
      >
        <ActivityListItems />
      </ReferenceManyField>
    </>
  );
};

import { User } from "../user/User";
import { EnumAddressCountry } from "./EnumAddressCountry";

export const EU_COUNTRIES = [
  "AUT",
  "BEL",
  "BGR",
  "HRV",
  "CYP",
  "CZE",
  "DNK",
  "EST",
  "FIN",
  "FRA",
  "DEU",
  "GRC",
  "HUN",
  "IRL",
  "ITA",
  "LVA",
  "LTU",
  "LUX",
  "MLT",
  "NLD",
  "POL",
  "PRT",
  "ROU",
  "SVK",
  "SVN",
  "ESP",
  "SWE",
];

export const ADDRESS_COUNTRY_READABLE = new Map<EnumAddressCountry, string>([
  [EnumAddressCountry.DEU, "Deutschland"],
  [EnumAddressCountry.AUT, "Österreich"],
  [EnumAddressCountry.CHE, "Schweiz"],
  [EnumAddressCountry.CHN, "China"],
  [EnumAddressCountry.ITA, "Italien"],
  [EnumAddressCountry.NLD, "Niederlande"],
  [EnumAddressCountry.NZL, "Neuseeland"],
  [EnumAddressCountry.EST, "Estland"],
  [EnumAddressCountry.POL, "Polen"],
  [EnumAddressCountry.FRA, "Frankreich"],
  [EnumAddressCountry.ESP, "Spanien"],
  [EnumAddressCountry.BEL, "Belgien"],
  [EnumAddressCountry.GBR, "Grossbritannien"],
  [EnumAddressCountry.LUX, "Luxemburg"],
  [EnumAddressCountry.IRL, "Irland"],
  [EnumAddressCountry.TUR, "Türkei"],
  [EnumAddressCountry.IND, "Indien"],
  [EnumAddressCountry.MEX, "Mexiko"],
  [EnumAddressCountry.ISR, "Israel"],
  [EnumAddressCountry.ISL, "Island"],
  [EnumAddressCountry.BRA, "Brasilien"],
  [EnumAddressCountry.GRC, "Griechenland"],
  [EnumAddressCountry.TUN, "Tunesien"],
  [EnumAddressCountry.USA, "Vereinigte Staaten von Amerika"],
  [EnumAddressCountry.DZA, "Algerien"],
  [EnumAddressCountry.PRT, "Portugal"],
  [EnumAddressCountry.MAR, "Marokko"],
  [EnumAddressCountry.BGR, "Bulgarien"],
  [EnumAddressCountry.BGD, "Bangladesch"],
  [EnumAddressCountry.BLR, "Belarus"],
  [EnumAddressCountry.OMN, "Oman"],
  [EnumAddressCountry.TWN, "Taiwan"],
  [EnumAddressCountry.DNK, "Dänemark"],
  [EnumAddressCountry.DOM, "Dominikanische Republik"],
  [EnumAddressCountry.CAN, "Kanada"],
  [EnumAddressCountry.AUS, "Australien"],
  [EnumAddressCountry.ZAF, "Südafrika"],
  [EnumAddressCountry.SRB, "Serbien"],
  [EnumAddressCountry.ROU, "Rumänien"],
  [EnumAddressCountry.NGA, "Nigeria"],
  [EnumAddressCountry.EGY, "Ägypten"],
  [EnumAddressCountry.UGA, "Uganda"],
  [EnumAddressCountry.SAU, "Saudi-Arabien"],
  [EnumAddressCountry.HUN, "Ungarn"],
  [EnumAddressCountry.HKG, "Hongkong"],
  [EnumAddressCountry.JAM, "Jamaika"],
  [EnumAddressCountry.CYM, "Kaimaninseln"],
  [EnumAddressCountry.SGP, "Singapur"],
  [EnumAddressCountry.CHL, "Chile"],
  [EnumAddressCountry.KOR, "Republik Korea"],
  [EnumAddressCountry.RUS, "Russische Föderation"],
  [EnumAddressCountry.CZE, "Tschechische Republik"],
  [EnumAddressCountry.UKR, "Ukraine"],
  [EnumAddressCountry.LVA, "Lettland"],
  [EnumAddressCountry.ARE, "Vereinigte arabische Emirate"],
  [EnumAddressCountry.SWE, "Schweden"],
  [EnumAddressCountry.ALB, "Albanien"],
  [EnumAddressCountry.BIH, "Bosnien und Herzegowina"],
  [EnumAddressCountry.XKX, "Kosovo"],
  [EnumAddressCountry.HRV, "Kroatien"],
  [EnumAddressCountry.MNE, "Montenegro"],
  [EnumAddressCountry.MKD, "Ehemalige jugoslawische Republik Mazedonien"],
  [EnumAddressCountry.SVN, "Slowenien"],
  [EnumAddressCountry.SVK, "Slowakei"],
  [EnumAddressCountry.IDN, "Indonesien"],
  [EnumAddressCountry.NOR, "Norwegen"],
  [EnumAddressCountry.FIN, "Finnland"],
  [EnumAddressCountry.MLT, "Malta"],
  [EnumAddressCountry.PHL, "Philippinen"],
  [EnumAddressCountry.MYS, "Malaysia"],
  [EnumAddressCountry.VNM, "Vietnam"],
  [EnumAddressCountry.KWT, "Kuwait"],
  [EnumAddressCountry.HND, "Honduras"],
  [EnumAddressCountry.LTU, "Litauen"],
  [EnumAddressCountry.THA, "Thailand"],
  [EnumAddressCountry.PAK, "Pakistan"],
  [EnumAddressCountry.ZWE, "Simbabwe"],
  [EnumAddressCountry.KEN, "Kenia"],
  [EnumAddressCountry.ARG, "Argentinien"],
  [EnumAddressCountry.PER, "Peru"],
  [EnumAddressCountry.COL, "Kolumbien"],
  [EnumAddressCountry.GHA, "Ghana"],
  [EnumAddressCountry.CYP, "Zypern"],
  [EnumAddressCountry.QAT, "Katar"],
  [EnumAddressCountry.JPN, "Japan"],
  [EnumAddressCountry.JOR, "Jordanien"],
  [EnumAddressCountry.SEN, "Senegal"],
  [EnumAddressCountry.LBN, "Lebanon"],
  [EnumAddressCountry.IRQ, "Iraq"],
  [EnumAddressCountry.SYR, "Syria"],
  [EnumAddressCountry.AFG, "Afghanistan"],
  [EnumAddressCountry.YEM, "Yemen"],
  [EnumAddressCountry.IRN, "Iran"],
  [EnumAddressCountry.ARM, "Armenia"],
  [EnumAddressCountry.AZE, "Azerbaijan"],
  [EnumAddressCountry.GEO, "Georgia"],
  [EnumAddressCountry.KGZ, "Kyrgyzstan"],
  [EnumAddressCountry.KAZ, "Kazakhstan"],
  [EnumAddressCountry.TKM, "Turkmenistan"],
  [EnumAddressCountry.UZB, "Uzbekistan"],
  [EnumAddressCountry.TJK, "Tajikistan"],
  [EnumAddressCountry.ERI, "Eritrea"],
  [EnumAddressCountry.RUA, "Ruanda"],
  [EnumAddressCountry.BEN, "Benin"],
  [EnumAddressCountry.CMR, "Kamerun"],
  [EnumAddressCountry.GIN, "Guinea"],
  [EnumAddressCountry.TGO, "Togo"],
  [EnumAddressCountry.CIV, "Elfenbeinküste"],
  [EnumAddressCountry.BFA, "Burkina Faso"],
  [EnumAddressCountry.BDI, "Burundi"],
  [EnumAddressCountry.CPV, "Kap Verde"],
  [EnumAddressCountry.CAF, "Zentralafrikanische Republik"],
  [EnumAddressCountry.COM, "Komoren"],
  [EnumAddressCountry.COD, "Demokratische Republik Kongo"],
  [EnumAddressCountry.DJI, "Dschibuti"],
  [EnumAddressCountry.GNQ, "Äquatorialguinea"],
  [EnumAddressCountry.SWZ, "Eswatini"],
  [EnumAddressCountry.ETH, "Äthiopien"],
  [EnumAddressCountry.GAB, "Gabun"],
  [EnumAddressCountry.GMB, "Gambia"],
  [EnumAddressCountry.LSO, "Lesotho"],
  [EnumAddressCountry.LBR, "Liberia"],
  [EnumAddressCountry.MDG, "Madagaskar"],
  [EnumAddressCountry.MWI, "Malawi"],
  [EnumAddressCountry.MLI, "Mali"],
  [EnumAddressCountry.MOZ, "Mosambik"],
  [EnumAddressCountry.NAM, "Namibia"],
  [EnumAddressCountry.NER, "Niger"],
  [EnumAddressCountry.RWA, "Ruanda"],
  [EnumAddressCountry.STP, "São Tomé und Príncipe"],
  [EnumAddressCountry.SEN, "Senegal"],
  [EnumAddressCountry.SYC, "Seychellen"],
  [EnumAddressCountry.SLE, "Sierra Leone"],
  [EnumAddressCountry.SOM, "Somalia"],
  [EnumAddressCountry.SSD, "Südsudan"],
  [EnumAddressCountry.SDN, "Sudan"],
  [EnumAddressCountry.TZA, "Vereinigte Republik Tansania"],
  [EnumAddressCountry.ZMB, "Sambia"],
  [EnumAddressCountry.VEN, "Venezuela"],
  [EnumAddressCountry.URY, "Uruguay"],
  [EnumAddressCountry.AGO, "Angola"],
  [EnumAddressCountry.GTM, "Guatemala"],
  [EnumAddressCountry.SLV, "El Salvador"],
  [EnumAddressCountry.BOL, "Bolivia"],
  [EnumAddressCountry.CRI, "Costa Rica"],
  [EnumAddressCountry.CUB, "Cuba"],
  [EnumAddressCountry.ECU, "Ecuador"],
  [EnumAddressCountry.HTI, "Haiti"],
  [EnumAddressCountry.NIC, "Nicaragua"],
  [EnumAddressCountry.PAN, "Panama"],
  [EnumAddressCountry.PRY, "Paraguay"],
  [EnumAddressCountry.PRY, "Paraguay"],
  [EnumAddressCountry.GLP, "Guadeloupe"],
  [EnumAddressCountry.BHR, "Bahrain"],
  [EnumAddressCountry.MNG, "Mongolei"],
  [EnumAddressCountry.BMU, "Bermuda"],
  [EnumAddressCountry.JEY, "Jersey"],
]);

export const ADDRESS_COUNTRY_SHORT = new Map<EnumAddressCountry, string>([
  [EnumAddressCountry.DEU, "DE"],
  [EnumAddressCountry.AUT, "AT"],
  [EnumAddressCountry.CHE, "CH"],
  [EnumAddressCountry.CHN, "CN"],
  [EnumAddressCountry.ITA, "IT"],
  [EnumAddressCountry.NLD, "NL"],
  [EnumAddressCountry.NZL, "NZ"],
  [EnumAddressCountry.EST, "EE"],
  [EnumAddressCountry.POL, "PL"],
  [EnumAddressCountry.FRA, "FR"],
  [EnumAddressCountry.ESP, "ES"],
  [EnumAddressCountry.BEL, "BE"],
  [EnumAddressCountry.BEN, "BN"],
  [EnumAddressCountry.GBR, "UK"], // because people do this wrong
  [EnumAddressCountry.GBR, "GB"],
  [EnumAddressCountry.LUX, "LU"],
  [EnumAddressCountry.ISL, "IS"],
  [EnumAddressCountry.IRL, "IE"],
  [EnumAddressCountry.TUR, "TR"],
  [EnumAddressCountry.IND, "IN"],
  [EnumAddressCountry.MEX, "MX"],
  [EnumAddressCountry.ISR, "IL"],
  [EnumAddressCountry.BRA, "BR"],
  [EnumAddressCountry.GRC, "GR"],
  [EnumAddressCountry.TUN, "TN"],
  [EnumAddressCountry.USA, "US"],
  [EnumAddressCountry.DZA, "DZ"],
  [EnumAddressCountry.PRT, "PT"],
  [EnumAddressCountry.MAR, "MA"],
  [EnumAddressCountry.BGR, "BG"],
  [EnumAddressCountry.BGD, "BD"],
  [EnumAddressCountry.BLR, "BY"],
  [EnumAddressCountry.OMN, "OM"],
  [EnumAddressCountry.TWN, "TW"],
  [EnumAddressCountry.DNK, "DK"],
  [EnumAddressCountry.DOM, "DO"],
  [EnumAddressCountry.CAN, "CA"],
  [EnumAddressCountry.AUS, "AU"],
  [EnumAddressCountry.ZAF, "ZA"],
  [EnumAddressCountry.SRB, "RS"],
  [EnumAddressCountry.ROU, "RO"],
  [EnumAddressCountry.NGA, "NG"],
  [EnumAddressCountry.EGY, "EG"],
  [EnumAddressCountry.UGA, "UG"],
  [EnumAddressCountry.SAU, "SA"],
  [EnumAddressCountry.HUN, "HU"],
  [EnumAddressCountry.HKG, "HK"],
  [EnumAddressCountry.CYM, "KY"],
  [EnumAddressCountry.SGP, "SG"],
  [EnumAddressCountry.CHL, "CL"],
  [EnumAddressCountry.KOR, "KR"],
  [EnumAddressCountry.RUS, "RU"],
  [EnumAddressCountry.RUA, "RA"],
  [EnumAddressCountry.CZE, "CZ"],
  [EnumAddressCountry.UKR, "UA"],
  [EnumAddressCountry.LVA, "LV"],
  [EnumAddressCountry.ARE, "AE"],
  [EnumAddressCountry.SWE, "SE"],
  [EnumAddressCountry.ALB, "AL"],
  [EnumAddressCountry.BIH, "BA"],
  [EnumAddressCountry.XKX, "XK"],
  [EnumAddressCountry.HRV, "HR"],
  [EnumAddressCountry.MNE, "ME"],
  [EnumAddressCountry.MKD, "MK"],
  [EnumAddressCountry.SVN, "SI"],
  [EnumAddressCountry.SVK, "SK"],
  [EnumAddressCountry.IDN, "ID"],
  [EnumAddressCountry.NOR, "NO"],
  [EnumAddressCountry.FIN, "FI"],
  [EnumAddressCountry.MLT, "MT"],
  [EnumAddressCountry.PHL, "PH"],
  [EnumAddressCountry.MYS, "MY"],
  [EnumAddressCountry.VNM, "VN"],
  [EnumAddressCountry.GHA, "GH"],
  [EnumAddressCountry.THA, "TH"],
  [EnumAddressCountry.PAK, "PK"],
  [EnumAddressCountry.ZWE, "ZW"],
  [EnumAddressCountry.KEN, "KE"],
  [EnumAddressCountry.ARG, "AR"],
  [EnumAddressCountry.PER, "PE"],
  [EnumAddressCountry.COL, "CO"],
  [EnumAddressCountry.KWT, "KW"],
  [EnumAddressCountry.HND, "HN"],
  [EnumAddressCountry.LTU, "LT"],
  [EnumAddressCountry.CYP, "CY"],
  [EnumAddressCountry.QAT, "QA"],
  [EnumAddressCountry.JPN, "JP"],
  [EnumAddressCountry.JOR, "JO"],
  [EnumAddressCountry.SEN, "SN"],
  [EnumAddressCountry.LBN, "LB"],
  [EnumAddressCountry.IRQ, "IQ"],
  [EnumAddressCountry.SYR, "SY"],
  [EnumAddressCountry.AFG, "AF"],
  [EnumAddressCountry.YEM, "YE"],
  [EnumAddressCountry.IRN, "IR"],
  [EnumAddressCountry.ARM, "AM"],
  [EnumAddressCountry.AZE, "AZ"],
  [EnumAddressCountry.GEO, "GE"],
  [EnumAddressCountry.KGZ, "KG"],
  [EnumAddressCountry.KAZ, "KZ"],
  [EnumAddressCountry.TKM, "TM"],
  [EnumAddressCountry.UZB, "UZ"],
  [EnumAddressCountry.TJK, "TJ"],
  [EnumAddressCountry.ERI, "ER"],
  [EnumAddressCountry.CMR, "CM"],
  [EnumAddressCountry.GIN, "GN"],
  [EnumAddressCountry.TGO, "TG"],
  [EnumAddressCountry.CIV, "CI"],
  [EnumAddressCountry.BFA, "BF"],
  [EnumAddressCountry.BDI, "BI"],
  [EnumAddressCountry.CPV, "CV"],
  [EnumAddressCountry.CAF, "CF"],
  [EnumAddressCountry.COM, "KM"],
  [EnumAddressCountry.COD, "CD"],
  [EnumAddressCountry.DJI, "DJ"],
  [EnumAddressCountry.GNQ, "GQ"],
  [EnumAddressCountry.SWZ, "SZ"],
  [EnumAddressCountry.ETH, "ET"],
  [EnumAddressCountry.GAB, "GA"],
  [EnumAddressCountry.GMB, "GM"],
  [EnumAddressCountry.LSO, "LS"],
  [EnumAddressCountry.LBR, "LR"],
  [EnumAddressCountry.MDG, "MG"],
  [EnumAddressCountry.MWI, "MW"],
  [EnumAddressCountry.MLI, "ML"],
  [EnumAddressCountry.MOZ, "MZ"],
  [EnumAddressCountry.NAM, "NA"],
  [EnumAddressCountry.NER, "NE"],
  [EnumAddressCountry.RWA, "RW"],
  [EnumAddressCountry.STP, "ST"],
  [EnumAddressCountry.SYC, "SC"],
  [EnumAddressCountry.SLE, "SL"],
  [EnumAddressCountry.SOM, "SO"],
  [EnumAddressCountry.SSD, "SS"],
  [EnumAddressCountry.SDN, "SD"],
  [EnumAddressCountry.TZA, "TZ"],
  [EnumAddressCountry.ZMB, "ZM"],
  [EnumAddressCountry.JAM, "JM"],
  [EnumAddressCountry.VEN, "VE"],
  [EnumAddressCountry.UGA, "UY"],
  [EnumAddressCountry.AGO, "AO"],
  [EnumAddressCountry.GTM, "GT"],
  [EnumAddressCountry.SLV, "SV"],
  [EnumAddressCountry.BOL, "BO"],
  [EnumAddressCountry.CRI, "CR"],
  [EnumAddressCountry.CUB, "CU"],
  [EnumAddressCountry.ECU, "EC"],
  [EnumAddressCountry.HTI, "HT"],
  [EnumAddressCountry.NIC, "NI"],
  [EnumAddressCountry.PAN, "PA"],
  [EnumAddressCountry.PRY, "PY"],
  [EnumAddressCountry.GLP, "GP"],
  [EnumAddressCountry.BHR, "BH"],
  [EnumAddressCountry.MNG, "MN"],
  [EnumAddressCountry.BMU, "BM"],
  [EnumAddressCountry.JEY, "JE"],
]);

const EnumCountryCodePhoneNumberPrefix = new Map<EnumAddressCountry, string>([
  [EnumAddressCountry.DEU, "49"],
  [EnumAddressCountry.AUT, "43"],
  [EnumAddressCountry.CHE, "41"],
  [EnumAddressCountry.NLD, "31"],
  [EnumAddressCountry.ITA, "39"],
  [EnumAddressCountry.POL, "48"],
  [EnumAddressCountry.FRA, "33"],
  [EnumAddressCountry.ESP, "34"],
  [EnumAddressCountry.BEL, "32"],
  [EnumAddressCountry.GBR, "44"],
  [EnumAddressCountry.LUX, "352"],
  [EnumAddressCountry.IRL, "353"],
  [EnumAddressCountry.ISL, "354"],
  [EnumAddressCountry.TUR, "90"],
  [EnumAddressCountry.IND, "91"],
  [EnumAddressCountry.MEX, "52"],
  [EnumAddressCountry.ISR, "972"],
  [EnumAddressCountry.BRA, "55"],
  [EnumAddressCountry.GRC, "30"],
  [EnumAddressCountry.TUN, "216"],
  [EnumAddressCountry.USA, "1"],
  [EnumAddressCountry.DZA, "213"],
  [EnumAddressCountry.PRT, "351"],
  [EnumAddressCountry.BEN, "229"],
  [EnumAddressCountry.RUA, "250"],
  [EnumAddressCountry.MAR, "212"],
  [EnumAddressCountry.BGR, "359"],
  [EnumAddressCountry.BGD, "880"],
  [EnumAddressCountry.BLR, "375"],
  [EnumAddressCountry.OMN, "968"],
  [EnumAddressCountry.TWN, "886"],
  [EnumAddressCountry.DNK, "45"],
  [EnumAddressCountry.CAN, "1"],
  [EnumAddressCountry.AUS, "61"],
  [EnumAddressCountry.ZAF, "27"],
  [EnumAddressCountry.SRB, "381"],
  [EnumAddressCountry.ROU, "40"],
  [EnumAddressCountry.NGA, "234"],
  [EnumAddressCountry.EGY, "20"],
  [EnumAddressCountry.UGA, "256"],
  [EnumAddressCountry.SAU, "966"],
  [EnumAddressCountry.KOR, "82"],
  [EnumAddressCountry.RUS, "7"],
  [EnumAddressCountry.ARE, "971"],
  [EnumAddressCountry.LBN, "961"],
  [EnumAddressCountry.IRQ, "964"],
  [EnumAddressCountry.SYR, "963"],
  [EnumAddressCountry.AFG, "93"],
  [EnumAddressCountry.YEM, "967"],
  [EnumAddressCountry.IRN, "98"],
  [EnumAddressCountry.ARM, "374"],
  [EnumAddressCountry.AZE, "994"],
  [EnumAddressCountry.GEO, "995"],
  [EnumAddressCountry.KGZ, "996"],
  [EnumAddressCountry.KAZ, "7"],
  [EnumAddressCountry.TKM, "993"],
  [EnumAddressCountry.UZB, "998"],
  [EnumAddressCountry.TJK, "992"],
  [EnumAddressCountry.ERI, "291"],
  [EnumAddressCountry.CMR, "237"],
  [EnumAddressCountry.GIN, "224"],
  [EnumAddressCountry.TGO, "228"],
  [EnumAddressCountry.CIV, "225"],
  [EnumAddressCountry.BFA, "226"],
  [EnumAddressCountry.BDI, "257"],
  [EnumAddressCountry.CPV, "238"],
  [EnumAddressCountry.CAF, "236"],
  [EnumAddressCountry.COM, "269"],
  [EnumAddressCountry.COD, "243"],
  [EnumAddressCountry.DJI, "253"],
  [EnumAddressCountry.GNQ, "240"],
  [EnumAddressCountry.SWZ, "268"],
  [EnumAddressCountry.ETH, "251"],
  [EnumAddressCountry.GAB, "241"],
  [EnumAddressCountry.GMB, "220"],
  [EnumAddressCountry.LSO, "266"],
  [EnumAddressCountry.LBR, "231"],
  [EnumAddressCountry.MDG, "261"],
  [EnumAddressCountry.MWI, "265"],
  [EnumAddressCountry.MLI, "223"],
  [EnumAddressCountry.MOZ, "258"],
  [EnumAddressCountry.NAM, "264"],
  [EnumAddressCountry.NER, "227"],
  [EnumAddressCountry.RWA, "250"],
  [EnumAddressCountry.STP, "239"],
  [EnumAddressCountry.SYC, "248"],
  [EnumAddressCountry.SLE, "232"],
  [EnumAddressCountry.SOM, "252"],
  [EnumAddressCountry.SSD, "211"],
  [EnumAddressCountry.SDN, "249"],
  [EnumAddressCountry.TZA, "255"],
  [EnumAddressCountry.ZMB, "260"],
  [EnumAddressCountry.JAM, "876"],
  [EnumAddressCountry.VEN, "58"],
  [EnumAddressCountry.UGA, "598"],
  [EnumAddressCountry.AGO, "244"],
  [EnumAddressCountry.GTM, "502"],
  [EnumAddressCountry.SLV, "503"],
  [EnumAddressCountry.BOL, "591"],
  [EnumAddressCountry.CRI, "506"],
  [EnumAddressCountry.CUB, "53"],
  [EnumAddressCountry.ECU, "593"],
  [EnumAddressCountry.HTI, "509"],
  [EnumAddressCountry.NIC, "505"],
  [EnumAddressCountry.PAN, "507"],
  [EnumAddressCountry.PRY, "595"],
  [EnumAddressCountry.GLP, "590"],
  [EnumAddressCountry.BHR, "973"],
  [EnumAddressCountry.MNG, "976"],
  [EnumAddressCountry.BMU, "1441"],
  [EnumAddressCountry.JEY, "44"],
]);

export type COUNTRY =
  | "DEU"
  | "AUT"
  | "CHE"
  | "POL"
  | "CHN"
  | "EST"
  | "ITA"
  | "NLD"
  | "NZL"
  | "FRA"
  | "ESP"
  | "BEL"
  | "GBR"
  | "LUX"
  | "ISL"
  | "IRL"
  | "TUR"
  | "IND"
  | "MEX"
  | "ISR"
  | "BRA"
  | "GRC"
  | "TUN"
  | "USA"
  | "DZA"
  | "PRT"
  | "MAR"
  | "BGR"
  | "BGD"
  | "BLR"
  | "OMN"
  | "TWN"
  | "DNK"
  | "DOM"
  | "CAN"
  | "AUS"
  | "ZAF"
  | "SRB"
  | "ROU"
  | "NGA"
  | "EGY"
  | "UGA"
  | "SAU"
  | "HUN"
  | "HKG"
  | "CYM"
  | "SGP"
  | "CHL"
  | "KOR"
  | "RUS"
  | "CZE"
  | "UKR"
  | "LVA"
  | "ARE"
  | "SWE"
  | "ALB"
  | "BIH"
  | "XKX"
  | "HRV"
  | "MNE"
  | "MKD"
  | "SVN"
  | "SVK"
  | "IDN"
  | "NOR"
  | "FIN"
  | "MLT"
  | "PHL"
  | "COL"
  | "PER"
  | "ARG"
  | "KEN"
  | "ZWE"
  | "MYS"
  | "PAK"
  | "THA"
  | "VNM"
  | "GHA"
  | "KWT"
  | "HND"
  | "LTU"
  | "CYP"
  | "QAT"
  | "JPN"
  | "JOR"
  | "SEN"
  | "LBN"
  | "IRQ"
  | "SYR"
  | "AFG"
  | "YEM"
  | "IRN"
  | "ARM"
  | "AZE"
  | "GEO"
  | "KGZ"
  | "KAZ"
  | "TKM"
  | "UZB"
  | "TJK"
  | "ERI"
  | "RUA"
  | "BEN"
  | "CMR"
  | "GIN"
  | "TGO"
  | "CIV"
  | "BFA"
  | "BDI"
  | "CPV"
  | "CAF"
  | "COM"
  | "COD"
  | "DJI"
  | "GNQ"
  | "SWZ"
  | "ETH"
  | "GAB"
  | "GMB"
  | "LSO"
  | "LBR"
  | "MDG"
  | "MWI"
  | "MLI"
  | "MOZ"
  | "NAM"
  | "NER"
  | "RWA"
  | "STP"
  | "SEN"
  | "SYC"
  | "SLE"
  | "SOM"
  | "SSD"
  | "SDN"
  | "TZA"
  | "ZMB"
  | "JAM"
  | "VEN"
  | "AGO"
  | "URY"
  | "GTM"
  | "SLV"
  | "BOL"
  | "CRI"
  | "CUB"
  | "ECU"
  | "HTI"
  | "NIC"
  | "PAN"
  | "PRY"
  | "GLP"
  | "BHR"
  | "MNG"
  | "BMU"
  | "JEY";

export type Address = {
  addressLine1: string;
  addressLine2: string | null;
  country?: COUNTRY;
  languageCountry?: COUNTRY;
  createdAt: Date;
  id: string;
  postalcode: string;
  town: string;
  updatedAt: Date;
  user?: User | null;
  verificationStatus?: "VALID" | "INVALID" | "UNKNOWN" | null;
};

export function normalizePhoneNumber(
  input: string,
  countryCode: EnumAddressCountry = EnumAddressCountry.DEU,
  usePlus: boolean = false
): string {
  if (!input || input.length < 3) {
    return "";
  }
  // handle multiple numbers in input
  if (input.includes("/") || input.includes("|") || input.includes(",")) {
    let numbers;
    if (input.includes("/")) {
      numbers = input.split("/");
    } else if (input.includes("|")) {
      numbers = input.split("|");
    } else {
      numbers = input.split(",");
    }

    if (numbers.length === 1) {
      input = numbers[0];
    } else if (
      numbers.length > 1 &&
      numbers[0].length > 6 &&
      numbers[1].length > 6
    ) {
      return input;
    } else {
      input = `${numbers.join()}`; // fragments only, use full string as number
    }
  }

  input = input.replace(/\s+/g, ""); // remove spaces
  if (input.startsWith("+")) {
    return input;
  }

  input = input.replaceAll(/\D/g, "");
  let normalizedInput = input;
  if (input.startsWith("0")) {
    if (input.startsWith("00")) {
      normalizedInput = input.replace("00", "");
    }
    // remove leading 0
    normalizedInput = normalizedInput.replace(/^0/, "");
  } else if (
    input.startsWith("1") &&
    countryCode !== EnumAddressCountry.USA &&
    countryCode !== EnumAddressCountry.CAN &&
    EnumCountryCodePhoneNumberPrefix.get(countryCode)
  ) {
    return `00${EnumCountryCodePhoneNumberPrefix.get(
      countryCode
    )}${normalizedInput}`;
  }

  if (
    (input.startsWith("1") &&
      (countryCode === EnumAddressCountry.USA ||
        countryCode === EnumAddressCountry.CAN)) ||
    normalizedInput.substring(0, 2) ===
      EnumCountryCodePhoneNumberPrefix.get(countryCode) ||
    normalizedInput.substring(0, 3) ===
      EnumCountryCodePhoneNumberPrefix.get(countryCode)
  ) {
    return `00${normalizedInput}`;
  } else if (EnumCountryCodePhoneNumberPrefix.get(countryCode)) {
    normalizedInput = `00${EnumCountryCodePhoneNumberPrefix.get(
      countryCode
    )}${normalizedInput}`;
  }

  if (usePlus) {
    normalizedInput = `+${normalizedInput.replace("00", "")}`;
  }

  return normalizedInput;
}

import React from "react";
import { useGetIdentity } from "react-admin";
import { ClaimShowSidebar } from "../../Components/ClaimShowSidebar";
import { useSocket } from "./useSocket";

export const useClaimShowLogic = (claimId: string | undefined) => {
  const { identity } = useGetIdentity();
  const [absoluteTotal, setAbsoluteTotal] = React.useState(0);
  const [
    currentCreditorAmountDueInclInterest,
    setCurrentCreditorAmountDueInclInterest,
  ] = React.useState(0);

  const { socket, isConnected } = useSocket();

  React.useEffect(() => {
    if (isConnected && claimId && identity?.id) {
      socket.emit("userJoinsClaim", { claimId, userId: identity.id });

      return () => {
        socket.emit("userLeavesClaim", { claimId, userId: identity.id });
      };
    }
  }, [isConnected, socket, claimId, identity?.id]);

  const aside = React.useMemo(
    () => (
      <ClaimShowSidebar
        claimId={claimId}
        setAbsoluteTotal={setAbsoluteTotal}
        setCurrentCreditorAmountDueInclInterest={
          setCurrentCreditorAmountDueInclInterest
        }
      />
    ),
    [claimId]
  );

  return { aside, absoluteTotal, currentCreditorAmountDueInclInterest };
};

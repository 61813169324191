import * as React from "react";

import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useRecordContext,
} from "react-admin";

import {
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import {
  EnumPaymentPaymentType,
  NEGATIVE_PAYMENT_TYPES,
  THIRD_PARTY_PAYMENT_TYPES,
} from "../api/payment/EnumPaymentPaymentType";
import { Payment } from "../api/payment/Payment";
import { PaymentPlan } from "../api/paymentPlan/PaymentPlan";
import { CLAIM_TITLE_FIELD } from "../claim/ClaimTitle";
import { theme } from "../theme/theme";
import { USER_TITLE_FIELD } from "../user/UserTitle";
import { PAYMENTPLAN_TITLE_FIELD } from "./PaymentPlanTitle";

const FinancialOverview = (props: any) => {
  const record: PaymentPlan = useRecordContext();
  const dataProvider = useDataProvider();
  const [numberOfPaymentsDone, setNumberOfPaymentsDone] = useState(0);

  useEffect(() => {
    if (!record) {
      return;
    }

    const fetchData = async () => {
      const { data: paymentData } = await dataProvider.getList(
        "Payment",
        {
          pagination: { page: 1, perPage: 300 },
          sort: { field: "paymentDate", order: "DESC" },
          filter: { claim: { id: record?.claim?.id || 0 } },
        },
        { enabled: !!record?.claim?.id }
      );

      const payments: Payment[] = Object.values(paymentData) as Payment[];
      const incomingPayments = payments?.filter(
        (payment) =>
          payment.paymentType &&
          NEGATIVE_PAYMENT_TYPES.includes(
            payment.paymentType as EnumPaymentPaymentType
          ) &&
          payment.paymentType !== EnumPaymentPaymentType.WriteOffDiscount &&
          payment.paymentType !== EnumPaymentPaymentType.ExistingPayments &&
          !THIRD_PARTY_PAYMENT_TYPES.includes(
            payment.paymentType as EnumPaymentPaymentType
          )
      );
      const incomingPaymentsTotal: number = Math.abs(
        parseFloat(
          incomingPayments
            .reduce(function (a, b) {
              return a + b.amount;
            }, 0)
            ?.toFixed(2)
        )
      );
      setNumberOfPaymentsDone(incomingPayments?.length || 0);

      const { data: claimData } = await dataProvider.getOne("Claim", {
        id: record?.claim?.id || 0,
      });

      const amountToPayInPaymentPlan = parseFloat(
        ((claimData?.totalPending || 0) + incomingPaymentsTotal)?.toFixed(2)
      );
      const rate = (
        amountToPayInPaymentPlan / record?.numberOfPayments || 0
      )?.toFixed(2);
      props.setRate(rate);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  return (
    <>
      <Typography
        variant="caption"
        style={
          numberOfPaymentsDone === record?.numberOfPayments
            ? { color: "green" }
            : {}
        }
      >
        Rates paid: {numberOfPaymentsDone}/{record?.numberOfPayments}
      </Typography>
      <Typography variant="body2" style={{ fontWeight: 600 }}>
        Current Rate:{" "}
        {!record?.customPaymentRates
          ? Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(props.rate || "0")
          : Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(record.customPaymentRates[numberOfPaymentsDone] || "0")}
      </Typography>
    </>
  );
};

export const PaymentPlanShow = (props: ShowProps): React.ReactElement => {
  const [rate, setRate] = React.useState(0);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/PaymentPlan/" + props.id + "/edit", "_self");
      }
    },
    [props.id]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className="removeShowCard">
      <Show {...props}>
        <SimpleShowLayout>
          <Grid container spacing={2} alignItems={"stretch"}>
            <Grid item xs={12} md={6} style={{ display: "flex" }}>
              <Card className="keepCardStyle">
                <CardContent>
                  <List>
                    <ListItem alignItems={"flex-start"}>
                      <Typography variant="h6">{"Payment Plan"}</Typography>
                    </ListItem>
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            No. of Payments:
                          </Typography>
                        }
                        secondary={
                          <TextField
                            label="Number Of Payments"
                            source="numberOfPayments"
                            style={{ fontWeight: 600 }}
                          />
                        }
                      ></ListItemText>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        primary={
                          <Typography variant="caption">
                            {"Frequency:"}
                          </Typography>
                        }
                        aria-label="Frequency"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        secondary={
                          <TextField
                            label="Frequency"
                            source="frequency"
                            style={{ fontWeight: 600 }}
                          />
                        }
                      />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        primary={
                          <Typography variant="caption">
                            Payment Rate:
                          </Typography>
                        }
                        aria-label="Frequency"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        secondary={
                          <TextField
                            label="Custom Payment Rates"
                            source="customPaymentRates"
                            style={{ fontWeight: 600 }}
                          />
                        }
                      />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        primary={
                          <Typography variant="caption">Rates:</Typography>
                        }
                        aria-label="Rates"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        secondary={
                          <FinancialOverview rate={rate} setRate={setRate} />
                        }
                      />
                    </ListItem>
                    <Divider component="li" />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        primary={
                          <Typography variant="caption">
                            Next Payment Date:
                          </Typography>
                        }
                        aria-label="Frequency"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        secondary={
                          <DateField
                            label="Next Payment Due Date"
                            source="nextPaymentDueDate"
                            locales="de-DE"
                            color="error"
                            style={
                              rate <= 0
                                ? { display: "none" }
                                : { fontWeight: 600 }
                            }
                            options={{
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                              timeZone: "Europe/Berlin",
                            }}
                          />
                        }
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} style={{ display: "flex" }}>
              <Card className="keepCardStyle">
                <CardContent>
                  <List>
                    <ListItem alignItems={"flex-start"}>
                      <Typography variant="h6">
                        {"Payment Plan Details:"}
                      </Typography>
                    </ListItem>
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">Claim ID:</Typography>
                        }
                        secondary={
                          <ReferenceField
                            label="Claim"
                            source="claim.id"
                            reference="Claim"
                            link="show"
                          >
                            <TextField source={CLAIM_TITLE_FIELD} />
                          </ReferenceField>
                        }
                      ></ListItemText>
                    </ListItem>
                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Claim Debtor:
                          </Typography>
                        }
                        secondary={
                          <ReferenceField
                            label="Debtor"
                            source="debtor.id"
                            reference="User"
                          >
                            <TextField source={USER_TITLE_FIELD} />
                          </ReferenceField>
                        }
                      ></ListItemText>
                    </ListItem>

                    <Divider component="li" />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Active Status:
                          </Typography>
                        }
                        secondary={
                          <BooleanField label="Active" source="isActive" />
                        }
                      ></ListItemText>
                    </ListItem>

                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">
                            Court Status:
                          </Typography>
                        }
                        secondary={
                          <BooleanField
                            label="Has Court Title Note"
                            source="hasCourtTitleNote"
                          />
                        }
                      ></ListItemText>
                    </ListItem>

                    <Divider component="li" />
                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">Created at:</Typography>
                        }
                        secondary={
                          <DateField
                            source="createdAt"
                            label="Created At"
                            locales="de-DE"
                            options={{
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                              timeZone: "Europe/Berlin",
                            }}
                            style={{ fontWeight: 600 }}
                          />
                        }
                      ></ListItemText>
                    </ListItem>

                    <Divider component="li" />

                    <ListItem alignItems={"flex-start"}>
                      <ListItemText
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                        primary={
                          <Typography variant="caption">Updated at:</Typography>
                        }
                        secondary={
                          <DateField
                            source="updatedAt"
                            label="Updated At"
                            locales="de-DE"
                            options={{
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                              timeZone: "Europe/Berlin",
                            }}
                            style={{ fontWeight: 600 }}
                          />
                        }
                      ></ListItemText>
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Card style={{ marginTop: "1rem" }} className="keepCardStyle">
            <CardContent className="overflow-control">
              <Typography variant="h6" style={{ marginBottom: "1rem" }}>
                Payments
              </Typography>
              <ReferenceManyField
                reference="Payment"
                label={""}
                target="PaymentPlanId"
              >
                <Datagrid
                  optimized
                  rowClick="show"
                  rowStyle={(record) =>
                    record?.paymentType !==
                      EnumPaymentPaymentType.PaymentRate &&
                    record?.paymentType !== EnumPaymentPaymentType.DebtClearance
                      ? {
                          backgroundColor: "#ffebeb",
                        }
                      : {}
                  }
                >
                  <FunctionField
                    label="Amount"
                    render={(record: any) => {
                      if (
                        record?.paymentType !==
                          EnumPaymentPaymentType.PaymentRate &&
                        record?.paymentType !==
                          EnumPaymentPaymentType.DebtClearance
                      ) {
                        return <span>{record?.amount}</span>;
                      }

                      return (
                        <span
                          style={{
                            fontWeight: 600,
                            color:
                              -rate === record?.amount
                                ? theme.palette.success.dark
                                : theme.palette.warning.dark,
                          }}
                        >
                          {record?.amount}{" "}
                          {-rate !== record?.amount && " (" + rate + ")"}
                        </span>
                      );
                    }}
                  />
                  <DateField
                    source="createdAt"
                    label="Created At"
                    locales="de-DE"
                    options={{
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                      timeZone: "Europe/Berlin",
                    }}
                  />
                  <TextField label="ID" source="id" />
                  <TextField label="Payment Date" source="paymentDate" />
                  <ReferenceField
                    label="PaymentPlan"
                    source="paymentplan.id"
                    reference="PaymentPlan"
                  >
                    <TextField source={PAYMENTPLAN_TITLE_FIELD} />
                  </ReferenceField>
                  <TextField label="Payment Type" source="paymentType" />
                  <TextField label="Reference" source="reference" />
                  <DateField
                    source="updatedAt"
                    label="Updated At"
                    locales="de-DE"
                    options={{
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                      timeZone: "Europe/Berlin",
                    }}
                  />
                </Datagrid>
              </ReferenceManyField>
            </CardContent>
          </Card>
        </SimpleShowLayout>
      </Show>
    </div>
  );
};

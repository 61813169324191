import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import {
  Show,
  ShowProps,
  useDataProvider,
  useGetList,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {
  Activity,
  getReadableSubjectByClaimAction,
} from "../api/activity/Activity";
import { normalizePhoneNumber } from "../api/address/Address";
import { Payment } from "../api/payment/Payment";
import { PaymentPlan } from "../api/paymentPlan/PaymentPlan";
import { ClaimDrawer } from "../Components/ClaimDrawer";
import { ClaimHeaderBar } from "../Components/ClaimHeaderBar";
import { ClaimTabs } from "../Components/tabs/claimShow/ClaimTabs";
import { VIP_USERS } from "../config/AppConfig";
import { useClaimShowLogic } from "../context/hook/useClaimShowLogic";
import { useTitle } from "../context/hook/useTitle";
import { usePhone } from "../context/PhoneContext";

export const ClaimShow = (props: ShowProps): React.ReactElement => {
  const { aside } = useClaimShowLogic(props.id);

  return (
    <Show {...props} title="Claim" hasEdit={false} aside={aside}>
      <ClaimShowContent {...props} />
    </Show>
  );
};

const ClaimShowContent = (props: ShowProps) => {
  const { absoluteTotal, currentCreditorAmountDueInclInterest } =
    useClaimShowLogic(props.id);

  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [userCountry, setUserCountry] = React.useState();
  const { setShowAirCallPhone, phoneRef, isPhoneReady } = usePhone();
  const [hasLawyers, setHasLawyers] = React.useState(false);
  const [hasInsolvencyAdministrators, setHasInsolvencyAdministrators] =
    React.useState(false);
  const [paymentPlans, setPaymentPlans] = React.useState<PaymentPlan[]>([]);

  const { data: creditorData } = useGetOne("User", record?.creditor.id || 0, {
    enabled: !!record?.creditor.id,
  });
  const { data: debtorData } = useGetOne("User", record?.debtor.id || 0, {
    enabled: !!record?.debtor.id,
  });

  useTitle(
    record
      ? `${record.reference} ${
          creditorData?.businessName
            ? ` ${creditorData.businessName.slice(0, 3).toUpperCase()} `
            : ""
        } ./. ${
          debtorData?.businessName ||
          debtorData?.contactName ||
          debtorData?.name
        }`
      : ""
  );

  const fetchPaymentPlans = useCallback(async () => {
    if (record?.paymentPlans && record.paymentPlans.length > 0) {
      const { data } = await dataProvider.getMany("PaymentPlan", {
        ids: record.paymentPlans.map((paymentPlan: any) => paymentPlan.id),
      });
      setPaymentPlans(data as PaymentPlan[]);
    } else {
      setPaymentPlans([]);
    }
  }, [dataProvider, record?.paymentPlans]);

  const handleKeyPress = React.useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Claim/" + props?.id + "/edit", "_self");
      }

      if (event.target === document.body && event.key === "x") {
        window.open("/#/Claim/" + props?.id + "/edit", "_self");
      }
    },
    [props?.id]
  );

  React.useEffect(() => {
    // Attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // Remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    fetchPaymentPlans();

    return () => {
      setHasLawyers(false);
      setHasInsolvencyAdministrators(false);
    };
  }, [fetchPaymentPlans]);

  const handleCallDebtor = () => {
    setShowAirCallPhone(true);

    let debtorPhone = record?.debtor?.phone;

    if (!debtorPhone) {
      notify("No phone number available for this debtor", "warning");
      return;
    }
    debtorPhone = normalizePhoneNumber(debtorPhone, userCountry, true);

    phoneRef.current?.send(
      "dial_number",
      { phone_number: debtorPhone },
      (success, data) => {
        if (success) {
          notify("Dialing Call", "success");
        } else {
          notify("Could not dial", "warning");
        }
      }
    );
  };

  const { data: paymentData } = useGetList<Payment>(
    "Payment",
    { page: 1, perPage: 25 },
    { field: "paymentDate", order: "DESC" },
    { claim: { id: record?.id || 0 } },
    {
      enabled: !!record?.id,
    }
  );

  const { data: activityData } = useGetList<Activity>(
    "Activity",
    { page: 1, perPage: 25 },
    { field: "activityDate", order: "DESC" },
    { claim: { id: record?.id || 0 } },
    {
      enabled: !!record?.id,
    }
  );

  const statusToShow = useMemo(() => {
    return getStatusToShow(
      record,
      Object.values(activityData || {}) as Activity[],
      paymentPlans,
      Object.values(paymentData || {}) as Payment[]
    );
  }, [record, activityData, paymentPlans, paymentData]);

  return (
    <>
      <ClaimHeaderBar
        record={record}
        notify={notify}
        hasLawyers={hasLawyers}
        hasInsolvencyAdministrators={hasInsolvencyAdministrators}
        statusToShow={statusToShow}
        dataProvider={dataProvider}
        refresh={refresh}
        setUserCountry={setUserCountry}
      />
      <ClaimTabs
        absoluteTotal={absoluteTotal}
        currentCreditorAmountDueInclInterest={
          currentCreditorAmountDueInclInterest
        }
        handleCallDebtor={handleCallDebtor}
        isPhoneReady={isPhoneReady}
      />
      {VIP_USERS.includes(record?.identity?.username) && <ClaimDrawer />}
    </>
  );
};

export const getStatusToShow = (
  claim: any,
  activities: Activity[],
  paymentPlans: PaymentPlan[],
  payments: Payment[]
) => {
  let statusToShow = claim?.status || "";

  console.log("statusToShow", statusToShow);

  switch (statusToShow) {
    case "EncashmentLevel":
      if (paymentPlans?.length && paymentPlans[0].isActive) {
        statusToShow = "PaymentPlan";
      } else if (activities?.length) {
        const firstActivityWithClaimAction = activities.find(
          (activity) =>
            !activity.isPlanned &&
            activity.claimAction &&
            (activity.claimAction.endsWith("Dunning") ||
              activity.claimAction.endsWith("Reminder")) &&
            activity.result !== "Negative"
        );

        if (
          firstActivityWithClaimAction &&
          firstActivityWithClaimAction.claimAction
        ) {
          statusToShow = firstActivityWithClaimAction.claimAction;
        }
      }
      break;
    case "Open":
    case "ReminderLevel":
    case "JudicialLevel":
      break;
    case "Closed":
    case "Paid":
      if (
        payments?.filter(
          (payment) => payment.paymentType === "Payout" && !payment.isPlanned
        ).length
      ) {
        statusToShow = "PaidOut";
      }
      break;
    default:
      break;
  }

  return getReadableSubjectByClaimAction(statusToShow);
};

import {
  Card,
  CardActionArea,
  CircularProgress,
  Divider,
  Grid,
  Input,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  ArrowDownward,
  CloseRounded,
  FileCopyOutlined,
  FolderOpenOutlined,
} from "@material-ui/icons";
import { format } from "date-fns";
import React from "react";
import {
  Button,
  DataProvider,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { defaultStyles, FileIcon } from "react-file-icon";
import { Claim } from "../../../../api/claim/Claim";
import { theme } from "../../../../theme/theme";
import { DATE_REGEX } from "../../../../util/ClaimUtils";
import { parseDate } from "../../../../util/DateUtils";

const renderFiles = (
  files: string[],
  allFiles: string[],
  record: Claim,
  notify: any,
  dataProvider: DataProvider,
  handleFileClick: any,
  setIsLoading: any
) =>
  files.map((url: string) => {
    let fileTitle = url
      .replace("media/", "")
      .replace(record?.reference + "/", "")
      .replace(record?.reference, "");

    const splitFileTitle = fileTitle.split(".");
    let fileDate;
    const fileExtension = splitFileTitle.pop();
    fileTitle = splitFileTitle.shift();

    const dateMatch = fileTitle?.match(DATE_REGEX);
    if (dateMatch) {
      fileDate = parseDate(dateMatch[1]);
      fileTitle = fileTitle
        .replace(DATE_REGEX, "")
        .replace(/^-/, "")
        .replace("--", "-")
        .replace(/^-/, "")
        .replace("--", "-")
        .replace(/^[\w]{2}-/, "")
        .replace(/-[\w]{2}$/, "")
        .trim()
        .replace(/ -$/, "")
        .replace(/-$/, "");
    }

    return (
      <Card
        key={url}
        variant="outlined"
        style={{ background: theme.palette.grey[100] }}
      >
        <CardActionArea
          onClick={() => handleFileClick(record, url, false, setIsLoading)}
        >
          <Grid container style={{ alignItems: "center" }}>
            <Grid
              xs={1}
              item
              style={{
                padding: 14,
                cursor: "pointer",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  height: 30,
                  width: 30,
                  margin: "0 auto",
                  marginTop: -5,
                }}
              >
                <FileIcon
                  labelUppercase
                  color={
                    !url.toLowerCase().includes("dunning") &&
                    !url.toLowerCase().includes("courtprocessintro") &&
                    !url.toLowerCase().includes("monitoring") &&
                    !url.toLowerCase().includes("paymentplan")
                      ? theme.palette.grey[300]
                      : theme.palette.secondary.main
                  }
                  extension={fileExtension}
                  {...defaultStyles[fileExtension]}
                />
              </div>
            </Grid>
            <Grid
              xs={4}
              lg={6}
              item
              style={{
                fontWeight: 600,
                fontSize: 14,
                paddingBottom: 15,
                paddingTop: 15,
                position: "relative",
              }}
            >
              {fileTitle || url}
            </Grid>
            <Grid
              xs={3}
              lg={2}
              item
              style={{
                padding: 15,
                color: theme.palette.grey[700],
                fontSize: 14,
                textAlign: "center",
              }}
            >
              {fileDate && <>{format(fileDate, "dd.MM.yyyy")}</>}
            </Grid>
            <Grid
              xs={4}
              lg={3}
              item
              style={{ textAlign: "right", position: "relative" }}
            >
              <CloseRounded
                style={{
                  height: 16,
                  cursor: "pointer",
                  color: theme.palette.error.main,
                  padding: 15,
                  right: 90,
                  top: -22,
                  verticalAlign: "middle",
                  position: "absolute",
                  zIndex: 3,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    window.confirm("Do you really want to remove this file?")
                  ) {
                    dataProvider.update("Claim", {
                      id: record.id,
                      data: {
                        fileUrls: JSON.stringify(
                          allFiles.filter((parsedUrl) => parsedUrl !== url)
                        ),
                      },
                      previousData: record,
                    });
                  }
                }}
              />
              <Tooltip title="Copy file name">
                <FileCopyOutlined
                  style={{
                    height: 16,
                    cursor: "copy",
                    padding: 15,
                    right: 45,
                    top: -22,
                    verticalAlign: "middle",
                    position: "absolute",
                    zIndex: 3,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(fileTitle);
                    notify("Copied to clipboard", "info", null, false);
                  }}
                />
              </Tooltip>
              <Tooltip title="Copy path">
                <FolderOpenOutlined
                  style={{
                    height: 16,
                    cursor: "copy",
                    padding: 15,
                    right: 0,
                    top: -22,
                    verticalAlign: "middle",
                    position: "absolute",
                    zIndex: 3,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(url);
                    notify("Copied to clipboard", "info", null, false);
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    );
  });

const FileList = (): React.ReactElement => {
  let record: Claim = useRecordContext();
  let [internalFiles, setInternalFiles] = React.useState<string[]>([]);
  let [externalFiles, setExternalFiles] = React.useState<string[]>([]);
  let [allFiles, setAllFiles] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const refresh = useRefresh();
  const notify = useNotify();

  React.useEffect(() => {
    if (record?.fileUrls) {
      try {
        const urls = JSON.parse(record?.fileUrls as string);

        const matchingUrls = [];
        const nonMatchingUrls = [];

        const conditions = (url: string) =>
          !url.toLowerCase().includes("dunning") &&
          !url.toLowerCase().includes("courtprocessintro") &&
          !url.toLowerCase().includes("monitoring") &&
          !url.toLowerCase().includes("paymentplan");

        urls.forEach((url: string) => {
          if (conditions(url)) {
            nonMatchingUrls.push(url);
          } else {
            matchingUrls.push(url);
          }
        });

        setInternalFiles(matchingUrls);
        setExternalFiles(nonMatchingUrls);
        setAllFiles(urls);
      } catch (e) {
        // Handle error if necessary
      }
    }
  }, [record?.fileUrls]);

  const uploadProps = {
    name: "file",
    onChange: async (event: React.ChangeEvent<HTMLInputElement>) => {
      const authHeader = {
        headers: { Authorization: localStorage.getItem("credentials") || "" },
      };

      const files = event.target.files;

      if (files && files.length > 0) {
        try {
          for (const file of Array.from(files)) {
            const formData = new FormData();
            formData.append("file", file);

            await fetch(
              process.env.REACT_APP_SERVER_URL +
                "/api/claims/" +
                record?.id +
                "/files",
              {
                method: "POST",
                body: formData,
                ...authHeader,
              }
            );

            // Optional: You can add a delay between each request
            await new Promise((resolve) => setTimeout(resolve, 250));
          }

          notify("Uploaded successfully", "success", null, false);
          refresh();
        } catch (error) {
          notify("Upload failed", "error", null, false);
        }
      }
    },
  };

  function handleFileClick(
    record: Claim,
    url: string,
    saveFile?: boolean,
    setIsLoading?: any
  ): void {
    const authHeader = {
      headers: { Authorization: localStorage.getItem("credentials") || "" },
    };

    setIsLoading(true);
    let urlToFetch = url
      .split("media/" + record?.reference?.replaceAll("/", "-") + "/")
      .pop();
    urlToFetch = encodeURIComponent(urlToFetch || "");

    if (urlToFetch) {
      fetch(
        process.env.REACT_APP_SERVER_URL +
          "/api/claims/" +
          record?.reference?.replaceAll("/", "-") +
          "/file/" +
          urlToFetch,
        authHeader
      ).then((response) => {
        if (response.ok) {
          let anchor = document.createElement("a");
          document.body.appendChild(anchor);

          response.blob().then((blobby) => {
            const objectUrl = window.URL.createObjectURL(blobby);
            anchor.href = objectUrl;
            anchor.download = urlToFetch;
            anchor.target = "_blank";
            anchor.click();

            if (!saveFile) {
              window.open(objectUrl, "_blank"); // Open the file in a new tab
            }

            window.URL.revokeObjectURL(objectUrl);
            notify("File downloaded", "info", null, false);
          });
        }

        setIsLoading(false);
      });
    }
  }

  const useStyles = makeStyles((theme) => ({
    fileInput: {
      margin: "10px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px dashed #ccc",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "border-color 0.3s",
      "&:hover": {
        borderColor: "#999",
      },
    },
  }));
  const classes = useStyles();

  const dataProvider = useDataProvider();
  return (
    <>
      <div>
        <label htmlFor="file" className={classes.fileInput}>
          <Input
            id="file"
            name="file"
            type="file"
            style={{ width: "100%", padding: 20 }}
            inputProps={{ multiple: true }}
            {...uploadProps}
          />
        </label>
      </div>
      <br />
      <Divider />
      <br />
      <Typography variant="h5">
        Files {isLoading && <CircularProgress size={24} />}
      </Typography>
      <>
        {externalFiles && externalFiles.length > 0 && (
          <>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              Main Files
            </Typography>
            {renderFiles(
              externalFiles,
              allFiles,
              record,
              notify,
              dataProvider,
              handleFileClick,
              setIsLoading
            )}
            <Button
              variant="text"
              size="medium"
              style={{ marginTop: 10 }}
              startIcon={<ArrowDownward />}
              onClick={() =>
                externalFiles.map((url) =>
                  handleFileClick(record, url, true, setIsLoading)
                )
              }
              label="Download all main files"
            />
            <br />
            <br />
          </>
        )}

        {internalFiles && internalFiles.length > 0 && (
          <>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              Generated Files
            </Typography>
            {renderFiles(
              internalFiles,
              allFiles,
              record,
              notify,
              dataProvider,
              handleFileClick,
              setIsLoading
            )}
            <Button
              variant="text"
              size="medium"
              style={{ marginTop: 10 }}
              startIcon={<ArrowDownward />}
              onClick={() =>
                internalFiles.map((url) =>
                  handleFileClick(record, url, true, setIsLoading)
                )
              }
              label="Download all generated files"
            />
            <br />
            <br />
          </>
        )}
      </>
    </>
  );
};

export const FilesTab: React.FC = () => {
  return <FileList />;
};

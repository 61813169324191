import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Activity, ActivityTypes } from "../../api/activity/Activity";
import { theme } from "../../theme/theme";
import { TODAY } from "../../util/DateUtils";
import { getActivityTypeIcon } from "../icon/ActivityTypeIcon";

export const Automations: React.MemoExoticComponent<() => JSX.Element> =
  React.memo(() => {
    const [overdueAutomations, setOverdueAutomations] = useState([]);
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
      const authHeader = {
        headers: {
          Authorization: localStorage.getItem("credentials") || "",
          "Content-Type": "application/json",
        },
      };

      fetch(process.env.REACT_APP_SERVER_URL + "/api/activities/cron/monitor", {
        ...authHeader,
      })
        .then((response) => response.json())
        .then((parsedResponse) => {
          if (parsedResponse.length) {
            setOverdueAutomations(parsedResponse);
          }
        });
    }, []);

    return (
      <>
        <Card
          variant="outlined"
          style={{
            background:
              overdueAutomations && overdueAutomations.length > 0
                ? overdueAutomations.length > 100
                  ? theme.palette.error.main
                  : theme.palette.warning.main
                : theme.palette.success.main,
          }}
        >
          <CardContent className="flex-center" style={{ minHeight: 35 }}>
            <Typography variant="subtitle2">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setShowDetails(!showDetails);
                }}
                href={`/#/Activity?displayedFilters=%7B%7D&filter=%7B"isPlanned"%3Atrue%2C"activityDate"%3A"${
                  TODAY.toISOString().split("T")[0]
                }"%7D`}
                style={{
                  color: "white",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                Wartende Automations:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {overdueAutomations?.length &&
                  overdueAutomations?.length > 100
                    ? "🔥 "
                    : ""}
                  {overdueAutomations?.length ? overdueAutomations.length : "0"}
                  {overdueAutomations?.length &&
                  overdueAutomations?.length > 100
                    ? " 🔥"
                    : ""}
                </span>
              </a>
            </Typography>
          </CardContent>
        </Card>
        {showDetails && (
          <Card>
            <List
              style={{
                maxHeight: 300,
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              {overdueAutomations && (
                <React.Fragment>
                  {overdueAutomations.map((activity: Activity, key) => {
                    return (
                      <a
                        href={"#/Activity/" + activity.id + "/show"}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.text.primary,
                        }}
                      >
                        <ListItem
                          key={activity.id}
                          id={activity.id}
                          className="list-item-activity"
                        >
                          {getActivityTypeIcon(
                            activity.activityType as ActivityTypes
                          )}
                          <div className="col">
                            <span className="date">{`${format(
                              activity.activityDate,
                              "dd.MM.yyyy"
                            )}: `}</span>

                            <span className="reference">
                              {activity.claim?.reference}
                            </span>
                            <span className="company">
                              {activity.claim?.creditor?.businessName}
                            </span>
                          </div>
                        </ListItem>
                        {overdueAutomations.length - 1 !== key && <Divider />}
                      </a>
                    );
                  })}
                </React.Fragment>
              )}
            </List>
          </Card>
        )}
      </>
    );
  });

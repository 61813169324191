import AircallPhone from "aircall-everywhere";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

type CallLog = {
  id: string;
  payload: any;
  log: string;
  time: string;
};

type PhoneContextType = {
  showAirCallPhone: boolean;
  setShowAirCallPhone: Dispatch<SetStateAction<boolean>>;
  phoneRef: React.RefObject<any>;
  isPhoneReady: boolean;
  isLoggedIn: boolean;
  setIsPhoneReady: Dispatch<SetStateAction<boolean>>;
  statusMessage: string;
  setStatusMessage: Dispatch<SetStateAction<string>>;
  callLogs: CallLog[];
  addCallLog: (id: string, payload: any, log: string) => void;
  handleCheckLogin: () => void;
  initializeAircall: () => void;
};

const PhoneContext = createContext<PhoneContextType>({
  showAirCallPhone: false,
  phoneRef: { current: null },
  isPhoneReady: false,
  statusMessage: "",
  callLogs: [],
  isLoggedIn: false,
  setShowAirCallPhone: () => {},
  setIsPhoneReady: () => {},
  setStatusMessage: () => {},
  addCallLog: () => {},
  handleCheckLogin: () => {},
  initializeAircall: () => {},
});

export const PhoneProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showAirCallPhone, setShowAirCallPhone] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isPhoneReady, setIsPhoneReady] = useState(false);
  const [statusMessage, setStatusMessage] = useState("Aircall loading");
  const [callLogs, setCallLogs] = useState<CallLog[]>([]);
  const phoneRef = useRef<any>(null);

  const addCallLog = useCallback(
    (id: string, payload: any, log: string) => {
      setCallLogs((prev) => [
        ...prev,
        { id, payload, log, time: new Date().toLocaleTimeString() },
      ]);
      if (log !== statusMessage) {
        setStatusMessage(log);
      }
    },
    [statusMessage]
  );

  const handleCheckLogin = useCallback(() => {
    setIsPhoneReady(false);

    if (phoneRef.current) {
      phoneRef.current.isLoggedIn((response: boolean) => {
        setStatusMessage(response ? "Aircall ready" : "Aircall not ready");
        setIsLoggedIn(true);
        setIsPhoneReady(response);
        if (!response) {
          setStatusMessage("Login to Aircall");
        }
      });
    }
  }, []);

  const initializeAircall = useCallback(() => {
    if (phoneRef.current) return; // prevent reinitialization

    const phone = new AircallPhone({
      domToLoadPhone: "#phone",
      onLogin: (settings: any) => {
        setIsPhoneReady(true);
        handleCheckLogin();
        setStatusMessage("AirCall ready!");
        if (!settings?.user) {
          setIsPhoneReady(false);
          setStatusMessage("Login to Aircall");
        }
      },
      onLogout: () => {
        setStatusMessage("Aircall logged out");
        setIsPhoneReady(false);
      },
      debug: false,
    });

    phoneRef.current = phone;

    phone.on("incoming_call", (callInfo: any) => {
      const message = `Incoming call from ${callInfo.from} to ${callInfo.to} ringing!`;
      addCallLog("incoming_call", callInfo, message);
      setShowAirCallPhone(true);
    });

    phone.on("call_end_ringtone", (callInfo: any) => {
      const message = `Ringing ended. Call was ${callInfo.answer_status}`;
      addCallLog("call_end_ringtone", callInfo, message);
    });

    phone.on("call_ended", (callInfo: any) => {
      const message = `Call ended. Lasted ${callInfo.duration} seconds`;
      addCallLog("call_ended", callInfo, message);
    });

    phone.on("comment_saved", (callInfo: any) => {
      const message = `Comment about the last call saved`;
      addCallLog("comment_saved", callInfo, message);
    });
  }, [handleCheckLogin, addCallLog]);

  return (
    <PhoneContext.Provider
      value={{
        showAirCallPhone,
        setShowAirCallPhone,
        phoneRef,
        isPhoneReady,
        setIsPhoneReady,
        statusMessage,
        setStatusMessage,
        callLogs,
        addCallLog,
        handleCheckLogin,
        initializeAircall,
        isLoggedIn,
      }}
    >
      {children}
    </PhoneContext.Provider>
  );
};

export const usePhone = () => useContext(PhoneContext);

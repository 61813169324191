import { fetchUtils } from "react-admin";

export const aircallService = {
  createCallsCampaign: async (
    username: string,
    userId: any,
    phoneNumbers: string[]
  ) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/users/${userId}/callsCampaign`;
    const options = {
      method: "POST",
      body: JSON.stringify({ username, phoneNumbers }),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("credentials") || "",
      }),
    };
    return await fetchUtils.fetchJson(url, options);
  },

  getAircallStats: async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/api/users/dashboard/call-stats`;
    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: localStorage.getItem("credentials") || "",
      }),
    };
    return await fetchUtils.fetchJson(url, options);
  },
};

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";
import {
  AccountCircleOutlined,
  PhoneInTalkOutlined,
  PhoneLockedOutlined,
} from "@material-ui/icons";
import React, { useState } from "react";
import ReactSimplyCarousel from "react-simply-carousel";
import { usePhone } from "../../context/PhoneContext";

interface AirCallPhoneProps {
  hideStats?: boolean;
  killPadding?: boolean;
}

const AircallPhoneComponent: React.FC<AirCallPhoneProps> = ({
  hideStats = false,
  killPadding = false,
}) => {
  const {
    showAirCallPhone,
    setShowAirCallPhone,
    handleCheckLogin,
    isLoggedIn,
    isPhoneReady,
    statusMessage,
    callLogs,
  } = usePhone();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handlePhoneShow = () => {
    setShowAirCallPhone(!showAirCallPhone);
  };

  return (
    <Card variant="outlined" onClick={!isLoggedIn ? handleCheckLogin : null}>
      {!hideStats && callLogs.length > 0 && (
        <>
          <CardContent
            className="flex-center"
            style={{
              minHeight: killPadding ? 25 : 50,
            }}
          >
            <ReactSimplyCarousel
              activeSlideIndex={activeSlideIndex}
              itemsToScroll={1}
              itemsToShow={1}
              onRequestChange={setActiveSlideIndex}
              forwardBtnProps={{
                style: {
                  display: "none",
                },
              }}
              backwardBtnProps={{
                style: {
                  display: "none",
                },
              }}
              containerProps={{
                style: {
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  userSelect: "none",
                  minWidth: 200,
                },
              }}
              autoplayDelay={5000}
              speed={300}
              autoplay
            >
              {callLogs.map((log, index) => (
                <Typography
                  key={index}
                  style={{ minWidth: 200, minHeight: 15 }}
                >
                  <Typography
                    style={{
                      color: "var(--primary)",
                      paddingLeft: 5,
                      fontWeight: "bold",
                    }}
                    variant="subtitle2"
                  >
                    {log.log.includes("Lasted")
                      ? log.log.replace("Call ended. ", "Last Call duration")
                      : log.log ?? ""}
                  </Typography>
                </Typography>
              ))}
            </ReactSimplyCarousel>
          </CardContent>
          <Divider />
        </>
      )}

      <CardContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: 40,
        }}
      >
        <Typography
          variant={"body2"}
          style={{
            fontWeight: "bold",
            transition: "cubic-bezier(0.075, 0.82, 0.165, 1)",
            color: isPhoneReady ? "var(--success)" : "var(--warning)",
          }}
        >
          {statusMessage}
        </Typography>
        {statusMessage.includes("loading") && (
          <CircularProgress
            color={"secondary"}
            size={"1rem"}
            style={{ marginLeft: "1rem" }}
          />
        )}
        {statusMessage === "Login to Aircall" && (
          <Button
            style={{
              marginLeft: "1rem",
              backgroundColor: showAirCallPhone
                ? "var(--error)"
                : "var(--primary)",
              color: "white",
              fontWeight: "bold",
              position: "absolute",
              right: "1rem",
            }}
            variant={"outlined"}
            onClick={handlePhoneShow}
          >
            <AccountCircleOutlined />
          </Button>
        )}
        {isPhoneReady && (
          <Button
            style={{
              marginLeft: "1rem",
              backgroundColor: showAirCallPhone
                ? "var(--error)"
                : "var(--primary)",
              color: "white",
              fontWeight: "bold",
              position: "absolute",
              right: "1rem",
            }}
            variant={"outlined"}
            onClick={handlePhoneShow}
          >
            {showAirCallPhone ? (
              <PhoneLockedOutlined />
            ) : (
              <PhoneInTalkOutlined />
            )}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default AircallPhoneComponent;
